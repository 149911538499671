import { Button, CircularProgress } from '@mui/material';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { Loader } from '../../../../components';

const List = (props: Props) => {
    const { hasLoadingImages, nonLoadingImages, onClickGenerateMore, onClickImage, processing } = useLogic(props);

    if (hasLoadingImages && nonLoadingImages.length === 0)
        return <Loader text="Image generation may take up to 3 minutes. Please hang in here a little longer" />;

    return (
        <S.Container>
            <S.Images>
                {nonLoadingImages.map((image) => (
                    <S.ImageContainer key={image.stackId} onClick={() => onClickImage(image.stackId)}>
                        {(image.url ?? image.backgroundUrl) && <S.Image src={image.url ?? image.backgroundUrl} />}
                        {image.loading && <CircularProgress size={48} />}
                        <S.ImageOverlay>
                            <S.ImageOverlayContent>
                                <S.ImageOverlayBackground />
                                <S.ImageOverlayText color="white" fontWeight="bold" variant="body1">
                                    Edit
                                </S.ImageOverlayText>
                            </S.ImageOverlayContent>
                        </S.ImageOverlay>
                    </S.ImageContainer>
                ))}
                {hasLoadingImages && <S.Skeleton height={256} variant="rectangular" width="100%" />}
            </S.Images>
            <S.ButtonContainer>
                <Button
                    color="primary"
                    disabled={processing || hasLoadingImages}
                    onClick={onClickGenerateMore}
                    variant="contained"
                >
                    {processing ? <CircularProgress size={24} /> : 'Generate more'}
                </Button>
            </S.ButtonContainer>
        </S.Container>
    );
};

export default List;
