import { useMemo } from 'react';
import Basic from './Basic';
import { useSearchParams } from 'react-router-dom';

const Widget = () => {
    const [searchParams] = useSearchParams();

    const widgetType = useMemo(() => searchParams.get('widget'), [searchParams]);

    switch (widgetType) {
        case 'basic':
            return <Basic />;
        default:
            return null;
    }
};

export default Widget;
