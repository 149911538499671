import { styled } from '@mui/material/styles';

const BaseLayout = styled('div')`
    padding: 0 80px;
    min-width: 1024px;

    @media (max-width: 1024px) {
        padding: 0 16px;
    }
`;

export const Container = styled(BaseLayout)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const PageContainer = styled('div')`
    display: flex;
    flex: 1;
    overflow-y: scroll;
    width: 100%;

    @media (max-width: 600px) {
        padding: 8px;
    }
`;

export const ChildContainer = styled(BaseLayout)`
    position: absolute;
    left: 0;
    right: 0;
    top: 120px;
    bottom: 0;

    background-color: ${({ theme }) => theme.palette.layout.surface.low};
    display: flex;
`;

export const WidgetContainer = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
