import { css, styled } from '@mui/material/styles';

export const Paper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDragActive',
})<{ isDragActive: boolean }>`
    align-items: center;
    background-color: ${({ isDragActive, theme }) =>
        isDragActive ? theme.palette.layout.surface.mid : theme.palette.layout.surface.mid};
    border: 0.5px dashed;
    border-color: ${({ theme }) => theme.palette.layout.strokes};
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    p: 2;
    text-align: center;
    border-radius: 8px;
    height: 100%;
`;

export const Preview = styled('div')<{ url: string }>`
    background: url('${({ url }) => url}') center center no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
`;
