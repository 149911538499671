import download from './download';
import stack from './stack';
import storage from './storage';

const utils = {
    download,
    stack,
    storage,
};

export default utils;
