const storageUtils = {
    access: <T>(key: string) => ({
        get: (): T | null => {
            const value = localStorage.getItem(key);
            return value ? JSON.parse(value) : null;
        },
        set: (value: T) => {
            localStorage.setItem(key, JSON.stringify(value));
        },
    }),
};

export default storageUtils;
