import { styled } from '@mui/material/styles';

const SIZES = {
    small: { width: 112, height: 112 },
    large: { width: 215, height: 130 },
} as const;

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
`;

const BaseSelectionItem = styled('div')<{ selected: boolean; size: keyof typeof SIZES }>`
    width: ${({ size }) => SIZES[size].width}px;
    height: ${({ size }) => SIZES[size].height}px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
`;

export const Image = styled(BaseSelectionItem)<{ imageUrl: string }>`
    border: ${({ selected }) => (selected ? 2 : 0)}px solid ${({ theme }) => theme.palette.primary.main};
    background: url(${({ imageUrl }) => imageUrl}) no-repeat center center;
    background-size: ${({ size }) => SIZES[size].width}px;
`;

export const Placeholder = styled(BaseSelectionItem)`
    display: flex;
    border: ${({ selected }) => (selected ? 'solid' : 'dashed')} 2px
        ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.layout.onSurface.secondary)};
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #AAA;
`;

export const Images = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
`;
