import Actions from './Actions';
import { CircularProgress, IconButton } from '@mui/material';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { MaterialSymbol } from 'react-material-symbols';
import UndoRedo from './UndoRedo';

const Edit = (props: Props) => {
    const { onClose, onStackImageLoad, operationsBlocked, runOperation, stack, stackImage, stackImageSizeRatio } =
        useLogic(props);
    return (
        <S.Container>
            <S.Header>
                <IconButton onClick={onClose}>
                    <MaterialSymbol icon="arrow_back_ios" size={24} />
                </IconButton>
            </S.Header>

            <S.Body>
                <UndoRedo stack={stack} />
                <S.ImageContainer ratio={stackImageSizeRatio}>
                    {(stackImage?.url ?? stackImage?.backgroundUrl) && (
                        <S.Image
                            onLoad={onStackImageLoad}
                            src={stackImage.url ?? stackImage.backgroundUrl}
                            ratio={stackImageSizeRatio}
                        />
                    )}
                    {stackImage.loading && <CircularProgress size={48} />}
                </S.ImageContainer>
                <Actions operationsBlocked={operationsBlocked} runOperation={runOperation} stack={stack} />
            </S.Body>
        </S.Container>
    );
};

export default Edit;
