import { useCallback } from 'react';
import api from '../../../../../api';
import type { Props } from './types';

const useLogic = (props: Props) => {
    const stacksAPI = api.stack.useStacks();

    const onClickUndo = useCallback(async () => {
        await stacksAPI.revert(props.stack.id);
    }, [props.stack.id, stacksAPI.revert]);

    return { onClickUndo, processing: stacksAPI.revert.mutation.isPending };
};

export default useLogic;
