import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
    `,
    Text: styled(Typography)`
        text-align: center;
        max-width: 256px;
    `,
};

export default style;
