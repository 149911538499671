import api from '../../api';
import { OperationDto } from '../../api/types';
import { useEffect } from 'react';
import useWebsocketCommunication from '../useWebsocketCommunication';

const useWebsocketOperationUpdater = (groupId?: string) => {
    const operationCallbackSocket = useWebsocketCommunication('operationCallback');
    const stacksAPI = api.stack.useStacks(groupId);

    useEffect(() => {
        if (!operationCallbackSocket.message) return;

        const operation = operationCallbackSocket.message as OperationDto;
        stacksAPI.updateOperation(operation);
    }, [operationCallbackSocket.message]);
};

export default useWebsocketOperationUpdater;
