let jwtToken: string | undefined = undefined;
let userId: string | undefined = undefined;

const auth = {
    get token(): string | undefined {
        return jwtToken;
    },
    get userId(): string | undefined {
        return userId;
    },
    set token(token: string | undefined) {
        jwtToken = token;
    },
    set userId(id: string | undefined) {
        userId = id;
    },
};

export default auth;
