import { Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    ButtonContainer: styled('div')`
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 8px;
    `,
    Container: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;
        overflow-y: scroll;
        width: 100%;

        -ms-overflow-style: none;
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    `,
    Image: styled('img')`
        width: 100%;
    `,
    ImageContainer: styled('div')`
        align-items: center;
        border: 1px solid lightgray;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 256px;
        position: relative;
        width: 100%;
    `,
    ImageOverlay: styled('div')`
        align-items: center;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        opacity: 0;
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
        transition: opacity 0.2s ease-in-out;
        width: 100%;

        &:hover {
            opacity: 1;
        }
    `,
    ImageOverlayBackground: styled('div')`
        background-color: black;
        height: 100%;
        opacity: 0.2;
        width: 100%;
    `,
    ImageOverlayContent: styled('div')`
        height: 100%;
        position: relative;
        width: 100%;
    `,
    ImageOverlayText: styled(Typography)`
        left: 50%;
        position: absolute;
        text-align: center;
        top: 50%;
    `,
    Images: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    `,
    Skeleton: styled(Skeleton)`
        margin-top: 8px;
    `,
};

export default style;
