import { Button, CircularProgress } from '@mui/material';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';

const Generate = (props: Props) => {
    const { loading, onGenerate } = useLogic(props);

    return (
        <S.Container>
            <Button disabled={loading} onClick={onGenerate} variant="contained">
                {loading ? <CircularProgress size={24} /> : 'Generate model'}
            </Button>
        </S.Container>
    );
};

export default Generate;
