import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Container: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    `,
    Row: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 4px;
        justify-content: center;
        width: 100%;
    `,
    UpscaleButton: styled(IconButton)`
        border-color: lightgray;
        color: black;
    `,
};

export default style;
