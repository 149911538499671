import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Button: styled(Button)`
        padding: 12px 32px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        border-radius: 8px;
    `,
    Container: styled('div')`
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: flex-end;
        align-items: center;
        height: 80px;
    `,
};

export default style;
