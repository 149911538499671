import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import methods from './methods';
import { useCallback } from 'react';
import { OperationDto, StackDto } from './types';
import { CrudInfiniteListMethods, CrudMethods, useCrud, useCrudInfiniteList, useCrudList } from 'react-query-crud';

const fetchLimit = 20;

const api = {
    bots: {
        useDiscordConnection: () => {
            const crud = useCrud({
                data: methods.bots.discord.checkConnection,
                key: ['bots/discord'],
            });
            return {
                ...crud,
                connect: crud.method(CrudMethods.create(methods.bots.discord.connect)),
                disconnect: crud.method(CrudMethods.delete(methods.bots.discord.disconnect)),
            };
        },
    },
    comparison: {
        useComparison: (experimentId: string) => {
            const pairQueryKey = ['comparison', 'pair', experimentId];
            const pairQuery = useQuery({
                queryKey: pairQueryKey,
                queryFn: () => methods.comparison.getPair(experimentId),
            });
            const queryClient = useQueryClient();
            const selectMutation = useMutation({
                mutationFn: methods.comparison.select,
            });

            const pairRefetch = useCallback(async () => {
                const result = await pairQuery.refetch();
                queryClient.setQueryData(pairQueryKey, result.error ? null : result.data);
            }, [pairQuery.refetch]);

            return {
                select: selectMutation.mutateAsync,
                selectError: selectMutation.error,
                selectLoading: selectMutation.isPending,
                selectSuccess: selectMutation.isSuccess,
                pair: pairQuery.data,
                pairError: pairQuery.error,
                pairFetching: pairQuery.isFetching,
                pairRefetch,
            };
        },
    },
    operation: {
        useFileUpload: () => {
            return {
                getFileUploadDetails: methods.operation.getFileUploadDetails,
            };
        },
        useOperations: () => {
            const generateMutation = useMutation({ mutationFn: methods.operation.generate });
            const imagineMutation = useMutation({ mutationFn: methods.operation.imagine });
            const replaceFaceMutation = useMutation({ mutationFn: methods.operation.replaceFace });
            const replaceAreaMutation = useMutation({ mutationFn: methods.operation.replaceArea });
            const retouchBottomMutation = useMutation({ mutationFn: methods.operation.retouchBottom });
            const retouchDressMutation = useMutation({ mutationFn: methods.operation.retouchDress });
            const retouchFaceMutation = useMutation({ mutationFn: methods.operation.retouchFace });
            const retouchTopMutation = useMutation({ mutationFn: methods.operation.retouchTop });
            const retryMutation = useMutation({ mutationFn: methods.operation.retry });
            const uploadMutation = useMutation({ mutationFn: methods.operation.upload });
            const upscale4kMutation = useMutation({ mutationFn: methods.operation.upscale4k });

            return {
                generate: generateMutation.mutateAsync,
                imagine: imagineMutation.mutateAsync,
                replaceFace: replaceFaceMutation.mutateAsync,
                replaceArea: replaceAreaMutation.mutateAsync,
                retouchBottom: retouchBottomMutation.mutateAsync,
                retouchDress: retouchDressMutation.mutateAsync,
                retouchFace: retouchFaceMutation.mutateAsync,
                retouchTop: retouchTopMutation.mutateAsync,
                retry: retryMutation.mutateAsync,
                upload: uploadMutation.mutateAsync,
                upscale4k: upscale4kMutation.mutateAsync,
            };
        },
    },
    stack: {
        useStacks: (groupId?: string) => {
            const crud = useCrudInfiniteList<number, StackDto, { items: StackDto[] }, number | undefined>({
                key: groupId ? ['stacks', groupId] : ['stacks'],
                list: async (lastId: number | undefined) => {
                    const stacks = await methods.stack.getStacks({ lastId, limit: fetchLimit, groupId });
                    return { items: stacks };
                },
                listHasMore: (pages) => (pages.length > 0 ? pages[pages.length - 1].items.length === fetchLimit : true),
                listPageParam: (pages) => {
                    if (pages.length === 0) return undefined;

                    const lastPage = pages[pages.length - 1];
                    if (lastPage.items.length === 0) return undefined;

                    return lastPage.items[lastPage.items.length - 1].id;
                },
            });
            return {
                ...crud,
                createMany: crud.method({
                    run: (data: { amount?: number; generateOperationId: number }) =>
                        methods.stack.createStacks({ ...data, groupId }),
                    update: (pages, newStacks) => [{ items: newStacks }, ...pages],
                }),
                delete: crud.method(CrudInfiniteListMethods.delete(methods.stack.removeStack)),
                one: crud.one(methods.stack.getStack),
                pin: crud.method({
                    run: (id: number) => methods.stack.pinStack({ id }),
                    update: (pages, pinned, id) =>
                        pages.map((page) => ({
                            ...page,
                            items: page.items.map((item) => (item.id === id ? { ...item, pinned } : item)),
                        })),
                }),
                revert: crud.method({
                    run: (id: number) => methods.stack.undoStack({ id }),
                    update: (pages, stack) =>
                        pages.map((page) => ({
                            ...page,
                            items: page.items.map((item) => (item.id === stack.id ? stack : item)),
                        })),
                }),
                update: crud.method(CrudInfiniteListMethods.update(methods.stack.updateStack)),
                updateOperation: crud.method({
                    run: (_updatedOperation: OperationDto) => Promise.resolve(),
                    update: (pages, _result, updatedOperation) => {
                        return pages.map((page) => ({
                            ...page,
                            items: page.items.map((item) => ({
                                ...item,
                                operations: item.operations.map((operation) =>
                                    operation.id === updatedOperation.id ? updatedOperation : operation,
                                ),
                            })),
                        }));
                    },
                }),
            };
        },
    },
    subscription: {
        usePlans: () =>
            useCrudList({
                key: ['subscription', 'plans'],
                list: methods.subscription.getPlans,
            }),
        useSubscription: () => {
            const crud = useCrud({
                key: ['subscription', 'subscription'],
                data: methods.subscription.check,
            });
            return {
                ...crud,
                cancel: crud.method({
                    run: (plan: string) => methods.subscription.cancel(plan),
                    update: () => null,
                }),
                change: crud.method(CrudMethods.update(methods.subscription.upgrade)),
            };
        },
    },
};

export default api;
