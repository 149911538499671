import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DialogProps } from './types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useState } from 'react';

const ModalLayerContext = React.createContext<{
    showDialog: (props: DialogProps) => void;
}>({
    showDialog: () => {},
});

export const ModalLayerProvider = ({ children }: any) => {
    const [dialog, setDialog] = useState<DialogProps>();
    const onClose = useCallback(() => setDialog(undefined), []);

    const renderDialog = () => {
        if (!dialog) return null;

        return (
            <Dialog onClose={onClose} open>
                <DialogTitle>{dialog.title}</DialogTitle>
                <DialogContent>{dialog.text}</DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="text">
                        Close
                    </Button>
                    {dialog.actions.map((action) => (
                        <Button
                            onClick={() => {
                                onClose();
                                action.onClick();
                            }}
                            variant={action.variant ?? 'contained'}
                        >
                            {action.name}
                        </Button>
                    ))}
                </DialogActions>
            </Dialog>
        );
    };

    const value = useMemo(() => ({ showDialog: setDialog }), [setDialog]);
    return (
        <>
            <ModalLayerContext.Provider value={value}>{children}</ModalLayerContext.Provider>
            {renderDialog()}
        </>
    );
};

const useModalLayer = () => useContext(ModalLayerContext);

export default useModalLayer;
