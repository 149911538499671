const downloadUtils = {
    image: async (url: string) => {
        const response = await fetch(url, { mode: 'no-cors' });
        const blob = await response.blob();

        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    },
};

export default downloadUtils;
