import { styled } from '@mui/material/styles';

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    background-color: white;
    boder-radius: 24px;
    height: 480px;
`;

export const RightContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export const TabsContainer = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const ContentContainer = styled('div')``;

export const Header = styled('div')`
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 46px;
    gap: 8px;
`;
