import { useAuth0 } from '@auth0/auth0-react';
import { ProductFruits } from 'react-product-fruits';

const ProductFruitsProvider = () => {
    const { user, isAuthenticated } = useAuth0();

    const userInfo = {
        username: user?.sub ?? '', // REQUIRED - any unique user identifier
        // email: '==REPLACE==',
        // firstname: '==REPLACE==',
        // lastname: '==REPLACE==',
        // signUpAt: '==REPLACE==',
        // role: '==REPLACE==',
        // props: { customProp1: '==REPLACE==' },
    };

    return isAuthenticated ? <ProductFruits workspaceCode="4JasUCwvS3ogiJGu" language="en" user={userInfo} /> : null;
};

export default ProductFruitsProvider;
