import auth from '../../api/auth';
import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';

const useWebsocketCommunication = (topic: string) => {
    const [message, setMessage] = useState<any>();

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
            channelAuthorization: {
                endpoint: `${process.env.REACT_APP_WEB_APP_API_URL}/websocket/auth`,
                headers: { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${auth.token}` },
                transport: 'ajax',
            },
            cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
            forceTLS: true,
        });

        const channel = pusher.subscribe(`private-user-${auth.userId?.replace('|', '_')}`);
        channel.bind('pusher:subscription_succeeded', () => console.log('Websocket connected'));
        channel.bind('pusher:subscription_error', (data: any) => console.error(data));
        channel.bind(topic, (data: any) => setMessage(data));

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, []);

    return { message };
};

export default useWebsocketCommunication;
