import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Button: styled(Button)`
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 32px;
        border-top-left-radius: 0px;
        border-top-right-radius: 32px;
        height: 100%;
        width: 108px;
    `,
    Container: styled('div')`
        align-items: center;
        background-color: white;
        border: 0.5px solid ${({ theme }) => theme.palette.layout.strokes};
        border-radius: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background-color: ${({ theme }) => theme.palette.layout.surface.mid};
        max-width: 916px;
    `,
    Preview: styled('div')`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 46px;
        padding: 8px 12px 8px 12px;
    `,
};

export default style;
