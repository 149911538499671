import { Loader } from '../../../components';
import Edit from './Edit';
import Generate from './Generate';
import List from './List';
import useLogic from './logic';
import type { Props } from './types';

const Basic = (props: Props) => {
    const { input, page } = useLogic(props);

    switch (page) {
        case 'edit':
            return <Edit input={input} />;
        case 'generate':
            return <Generate input={input} />;
        case 'list':
            return <List input={input} />;
        case 'loading':
            return <Loader />;
        default:
            return null;
    }
};

export default Basic;
