import { useCallback, useEffect, useState } from 'react';
import { IframeMessage } from './types';

const useIframeParentCommunication = () => {
    const [message, setMessage] = useState<IframeMessage>();

    useEffect(() => {
        const eventListener = (event: any) => {
            if (event.data.bus === 'hautech') setMessage(event.data);
        };
        window.addEventListener('message', eventListener);
        return () => {
            window.removeEventListener('message', eventListener);
        };
    }, []);
    const sendMessage = useCallback(
        (topic: string, data: any) => window.parent.postMessage({ bus: 'hautech', data, topic }, '*'),
        [],
    );

    return { message, sendMessage };
};

export default useIframeParentCommunication;
