import { StackDto } from '../../api/types';

export type StackImage = {
    backgroundUrl: string | undefined;
    loading: boolean;
    stack: StackDto;
    stackId: number;
    updatedAt: Date;
    url: string | undefined;
};

const stackUtils = {
    getStackImage: (stack: StackDto): StackImage => {
        const emptyImage = {
            backgroundUrl: undefined,
            loading: true,
            stack,
            stackId: stack.id,
            updatedAt: new Date(),
            url: undefined,
        };
        if (
            stack.operations.length === 1 &&
            (stack.operations[0].operationId === 'upload' || stack.operations[0].operationId === 'imagine')
        ) {
            const originalOperation = stack.operations[0];
            if (originalOperation.status !== 'completed') return emptyImage;
            return {
                backgroundUrl: undefined,
                loading: false,
                stack,
                stackId: stack.id,
                updatedAt: stack.operations[stack.operations.length - 1].updatedAt,
                url: originalOperation.outputData?.imageUrl,
            };
        }

        if (
            stack.operations.length === 2 &&
            (stack.operations[1].operationId === 'generate' || stack.operations[1].operationId === 'imagine')
        ) {
            const generateOperatation = stack.operations[1];
            if (generateOperatation.status !== 'completed') return emptyImage;

            const resultsImageUrls = generateOperatation.outputData?.resultImageUrls;
            if (!resultsImageUrls) return emptyImage;

            const index = stack.operations[0].inputData.index;
            return {
                backgroundUrl: undefined,
                loading: false,
                stack,
                stackId: stack.id,
                updatedAt: stack.operations[stack.operations.length - 1].updatedAt,
                url: resultsImageUrls[index],
            };
        }

        const lastOperation = stack.operations[0];
        return {
            backgroundUrl: stackUtils.getStackImage({ ...stack, operations: stack.operations.slice(1) })?.url,
            loading: !lastOperation.outputData,
            stack,
            stackId: stack.id,
            updatedAt: stack.operations[stack.operations.length - 1].updatedAt,
            url: lastOperation?.outputData?.imageUrl,
        };
    },
};

export default stackUtils;
