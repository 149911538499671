import { apiMethods } from './utils';
import {
    ComparisonPair,
    OperationDto,
    OperationRunResponse,
    PaginationParams,
    StackDto,
    SubscriptionPlan,
} from './types';

const methods = {
    bots: {
        discord: {
            checkConnection: async (): Promise<{ discordId: string } | null> => {
                const result = await apiMethods.get('/bots/discord/connection');
                return result;
            },
            connect: async (discordId: string): Promise<{ discordId: string }> => {
                await apiMethods.post('/bots/discord/connection', { discordId });
                return { discordId };
            },
            disconnect: async (): Promise<void> => {
                await apiMethods.delete('/bots/discord/connection', {});
            },
        },
    },
    comparison: {
        getPair: async (experimentId: string): Promise<ComparisonPair | null> => {
            const result = await apiMethods.get('/comparisons/pair', { experimentId });
            return result;
        },
        select: async (data: { pairId: number; selectedOutputId: number }): Promise<void> => {
            await apiMethods.post('/comparisons/select', data);
        },
    },
    operation: {
        getFileUploadDetails: async (): Promise<{ downloadUrl: string; uploadUrl: string }> => {
            const result = await apiMethods.get('/operations/file/upload');
            return result;
        },
        getGenerationDefaultSettings: async () => {
            const result = await apiMethods.get('/operations/defaults/generate');
            return result;
        },
        getOperations: async (pagination: PaginationParams): Promise<OperationDto[]> => {
            const result = await apiMethods.get('/operations/operations', pagination);
            return result.operations;
        },
        generate: async (data: {
            aspectRatio?: string;
            negativePrompt?: string;
            parameters?: any;
            prompt?: string;
            productImageUrl?: string;
            seed: number;
            version: string;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'generate' });
        },
        imagine: async (data: {
            aspectRatio?: string;
            prompt?: string;
            seed?: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'imagine' });
        },
        replaceFace: async (data: {
            imageUrl: string;
            previousOperationExternalId: string;
            previousOperationId: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'replaceFace' });
        },
        replaceArea: async (data: {
            imageUrl: string;
            previousOperationExternalId: string;
            previousOperationId: number;
            maskUrl: string;
            prompt: string;
            strength: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'replaceArea' });
        },
        retouchBottom: async (data: {
            imageUrl: string;
            previousOperationExternalId: string;
            previousOperationId: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'retouchBottom' });
        },
        retouchDress: async (data: {
            imageUrl: string;
            previousOperationExternalId: string;
            previousOperationId: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'retouchDress' });
        },
        retouchFace: async (data: {
            imageUrl: string;
            previousOperationExternalId: string;
            previousOperationId: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'retouchFace' });
        },
        retouchTop: async (data: {
            imageUrl: string;
            previousOperationExternalId: string;
            previousOperationId: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'retouchTop' });
        },
        retry: async (data: {
            previousOperationExternalId: string;
            previousOperationId: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'retry' });
        },
        setGenerationDefaultSettings: async (data: {
            aspectRatio?: string;
            negativePrompt?: string;
            version?: string;
        }): Promise<void> => {
            return apiMethods.post('/operations/defaults/generate', data);
        },
        upload: async (data: { imageUrl: string }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'upload' });
        },
        upscale4k: async (data: {
            imageUrl: string;
            previousOperationExternalId: string;
            previousOperationId: number;
            version: number;
        }): Promise<OperationRunResponse> => {
            return apiMethods.post('/operations/operation', { data, operationId: 'upscale4k' });
        },
    },
    subscription: {
        cancel: async (plan: string): Promise<void> => {
            await apiMethods.delete('/subscriptions/subscription', { plan });
        },
        check: async (): Promise<{ subscription: string; periodEnd: number; isTrialUsed: boolean; status: string }> => {
            const result = await apiMethods.get('/subscriptions/subscription');
            return result;
        },
        create: async (plan: string, referral?: string): Promise<string> => {
            const result = await apiMethods.post('/subscriptions/subscription', { plan, referral });
            return result.paymentUrl;
        },
        getPlans: async (): Promise<SubscriptionPlan[]> => {
            const result = await apiMethods.get('/subscriptions/plans');
            return result.plans;
        },
        upgrade: async (
            plan: string,
        ): Promise<{ subscription: string; periodEnd: number; isTrialUsed: boolean; status: string }> => {
            const result = await apiMethods.put('/subscriptions/subscription', { plan });
            return result;
        },
    },
    stack: {
        createStacks: async (data: {
            amount?: number;
            generateOperationId: number;
            groupId?: string;
        }): Promise<StackDto[]> => {
            const result = await apiMethods.post('/stacks/stacks', data);
            return result.stacks;
        },
        getStack: async (id: number): Promise<StackDto | null> => {
            const result = await apiMethods.get('/stacks/stack', { id });
            return result.stack;
        },
        getStacks: async (params: PaginationParams & { groupId?: string }): Promise<StackDto[]> => {
            const result = await apiMethods.get('/stacks/stacks', params);
            return result.stacks;
        },
        pinStack: async (data: { id: number }): Promise<boolean> => {
            const { pinned } = await apiMethods.post('/stacks/stack/pin', data);
            return pinned;
        },
        removeStack: async (data: { id: number }): Promise<void> => {
            await apiMethods.delete('/stacks/stack', data);
        },
        undoStack: async (data: { id: number }): Promise<StackDto> => {
            return apiMethods.post('/stacks/stack/undo', data);
        },
        updateStack: async (data: { id: number; data: { operationId: number } }): Promise<StackDto> => {
            return apiMethods.put('/stacks/stack', { id: data.id, operationId: data.data.operationId });
        },
    },
    user: {
        getUser: async (): Promise<{ id: string }> => {
            const result = await apiMethods.get('/user');
            return result;
        },
    },
};

export default methods;
