import 'react-material-symbols/rounded';

import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import { WidgetConfigProvider } from '../hooks/useWidgetConfig';
import { ModalLayerProvider } from '../components/ModalLayer';
import ProductFruitsProvider from './productFruits';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: 1,
            retryDelay: 500,
        },
    },
});

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const redirect_uri = process.env.REACT_APP_AUTH0_REDIRECT_URI;

const ProvidersWrapper = ({ children }: { children: React.ReactNode }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Auth0Provider clientId={clientId!} domain={domain!} authorizationParams={{ audience, redirect_uri }}>
            <ProductFruitsProvider />
            <QueryClientProvider client={queryClient}>
                <ModalLayerProvider>
                    <WidgetConfigProvider>
                        <BrowserRouter>{children}</BrowserRouter>
                    </WidgetConfigProvider>
                </ModalLayerProvider>
            </QueryClientProvider>
            {/* <ReactQueryDevtools client={queryClient} /> */}
        </Auth0Provider>
    </ThemeProvider>
);

export default ProvidersWrapper;
