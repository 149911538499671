import 'react-material-symbols/rounded';

import { Route, Routes } from 'react-router-dom';
import * as Layout from './Layout';
import ProvidersWrapper from './providers';
import Widget from './Widget';
import WidgetSetup from './WidgetSetup';

const WidgetApplication = () => (
    <ProvidersWrapper>
        <Routes>
            <Route path="/" element={<Layout.Widget />}>
                <Route index Component={WidgetSetup} />
                <Route path="/widget/:widgetId" Component={Widget} />
            </Route>
        </Routes>
    </ProvidersWrapper>
);

export default WidgetApplication;
