import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        layout: typeof layoutColors;
        actions: typeof actionsColors;
    }
    interface PaletteOptions {
        layout: typeof layoutColors;
        actions: typeof actionsColors;
    }
}

const layoutColors = {
    strokes: '#BDBDBD',
    onSurface: {
        primary: '#3D3D3D',
        secondary: '#656565',
        tertiary: '#989898',
    },
    surface: {
        low: '#FCFCFC',
        mid: '#EFEFEF',
    },
};

const actionsColors = {
    primary: '#517D89',
    error: '#D36560',
    onPrimary: '#F3F8F8',
};

const theme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTypography: {
            // styleOverrides: {
            //     h1: {
            //         fontFamily: '"Inter", sans-serif',
            //     },
            //     h2: {
            //         fontFamily: '"Inter", sans-serif',
            //     },
            //     h3: {
            //         fontFamily: '"Inter", sans-serif',
            //     },
            //     h4: {
            //         fontFamily: '"Inter", sans-serif',
            //     },
            //     h5: {
            //         fontFamily: '"Inter", sans-serif',
            //     },
            //     h6: {
            //         fontFamily: '"Inter", sans-serif',
            //     },
            // },
        },
    },
    palette: {
        primary: {
            main: '#517D89',
            light: '#DEF3F9',
        },
        layout: layoutColors,
        actions: actionsColors,
    },
    typography: {
        fontFamily: '"Inter", sans-serif',
        allVariants: {
            color: layoutColors.onSurface.primary,
        },
        button: {
            textTransform: 'none',
        },
    },
});

export default theme;
