import auth from './auth';

const baseUrl = process.env.REACT_APP_WEB_APP_API_URL;

const hande401Error = (promise: Promise<any>) => {
    return promise.then((result) => {
        if (result.status === 401) {
            window.location.reload();
            throw result;
        }
        return result;
    });
};

export const apiMethods = {
    delete: async (url: string, data: any = {}) => {
        await hande401Error(
            fetch(`${baseUrl}${url}`, {
                method: 'DELETE',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...(auth.token ? { Authorization: `Bearer ${auth.token}` } : {}),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }),
        );
    },
    get: async (url: string, params: any = {}) => {
        const filteredParams = Object.assign(
            {},
            ...Object.keys(params)
                .filter((key) => params[key] !== undefined)
                .map((key) => ({ [key]: params[key] })),
        );

        const queryString = new URLSearchParams(filteredParams).toString();
        const urlWithoutParams = `${baseUrl}${url}`;
        const fullUrl =
            Object.keys(filteredParams).length > 0 ? `${urlWithoutParams}?${queryString}` : urlWithoutParams;

        const response = await hande401Error(
            fetch(fullUrl, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...(auth.token ? { Authorization: `Bearer ${auth.token}` } : {}),
                    'Content-Type': 'application/json',
                },
            }),
        );
        return response.json();
    },
    post: async (url: string, data: any) => {
        const response = await hande401Error(
            fetch(`${baseUrl}${url}`, {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...(auth.token ? { Authorization: `Bearer ${auth.token}` } : {}),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }),
        );
        return response.json();
    },
    put: async (url: string, data: any = {}) => {
        const response = await hande401Error(
            fetch(`${baseUrl}${url}`, {
                method: 'PUT',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    ...(auth.token ? { Authorization: `Bearer ${auth.token}` } : {}),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }),
        );
        return response.json();
    },
};
