import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const style = {
    Container: styled('div')`
        display: flex;
        flex-direction: column;
        gap: 32px;
        height: 100%;
        overflow-y: scroll;
        width: 100%;
    `,
    EmptyState: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
    `,
    EmptyStateImage: styled('img')`
        height: 100px;
    `,
    Image: styled('img')`
        cursor: pointer;
        width: 100%;
        border-radius: 4px;
    `,
    ImageBar: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    `,
    ImageButtons: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    `,
    ImageContainer: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 8px;
        padding-right: 8px;
        position: relative;
        width: 100%;
    `,
    ImageSkeleton: styled(Skeleton)`
        height: 200px;
        margin-bottom: 16px;
        transform: scale(1, 1);
        width: 100%;
    `,
    LoaderContainer: styled('div')`
        align-items: center;
        bottom: 0px;
        display: flex;
        justify-content: center;
        left: 0px;
        pointer-events: none;
        position: absolute;
        right: 0px;
        top: 0px;
    `,
};

export default style;
