import React, { useContext, useMemo } from 'react';
import { useState } from 'react';

const WidgetConfigContext = React.createContext<{
    setState: (value: any) => void;
    state: any;
}>({
    setState: (value: any) => {},
    state: {},
});

export const WidgetConfigProvider = ({ children }: any) => {
    const [state, setState] = useState<any>({});

    const value = useMemo(() => ({ setState, state }), [setState, state]);
    return <WidgetConfigContext.Provider value={value}>{children}</WidgetConfigContext.Provider>;
};

const useWidgetConfig = () => useContext(WidgetConfigContext);

export default useWidgetConfig;
