import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { MaterialSymbol } from 'react-material-symbols';

const UndoRedo = (props: Props) => {
    const { onClickUndo, processing } = useLogic(props);

    return (
        <S.Container>
            <Tooltip title="Revert to previous state">
                <IconButton disabled={props.stack.operations.length === 2 || processing} onClick={onClickUndo}>
                    <MaterialSymbol icon="undo" size={24} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Redo reverted operation">
                <IconButton disabled>
                    <MaterialSymbol icon="redo" size={24} />
                </IconButton>
            </Tooltip>
        </S.Container>
    );
};

export default UndoRedo;
