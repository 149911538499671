import { Outlet, useOutlet } from 'react-router-dom';
import Stacks from '../Stacks';
import Header from './Header';
import * as S from './style';

export const Application = () => {
    const outlet = useOutlet();

    return (
        <S.Container>
            <Header showGenerateBar />
            <S.PageContainer>
                <Stacks />
            </S.PageContainer>
            {outlet?.type && <S.ChildContainer>{outlet}</S.ChildContainer>}
        </S.Container>
    );
};

export const Pages = () => {
    const outlet = useOutlet();

    return (
        <S.Container>
            <Header />
            <S.PageContainer>{outlet}</S.PageContainer>
        </S.Container>
    );
};

export const Widget = () => (
    <S.WidgetContainer>
        <S.PageContainer>
            <Outlet />
        </S.PageContainer>
    </S.WidgetContainer>
);
