import { css, FormControl, InputLabel, Select as MUISelect } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const useCustomStyles = makeStyles(() => ({
    noArrows: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
}));

export const Container = styled('div')`

`;

export const Select = styled(MUISelect)`
    color: ${({ theme }) => theme.palette.layout.onSurface.secondary};

    & .MuiInput-underline {
        borderbottomcolor: ${({ theme }) => theme.palette.primary.main};
    }

    & .MuiInput-underline:hover {
        border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    }

    & .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.layout.onSurface.secondary};
    }

    & .MuiSelect-outlined {
        padding: 2px 8px;
        background-color: ${({ theme }) => theme.palette.layout.surface.low};
    }
`;

export const SelectFormControl = styled(FormControl)`
    width: 100%;
`;
export const SelectInputLabel = styled(InputLabel)`
    color: ${({ theme }) => theme.palette.primary.main};
`;
