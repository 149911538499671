import { styled } from '@mui/material/styles';

export const Container = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    height: 48px;
    padding: 0 16px;
`;

export const Button = styled('div')<{ selected: boolean }>`
    cursor: pointer;
    outline: ${({ theme, selected }) => (selected ? `0.5px solid ${theme.palette.layout.strokes}` : 'none')};
    padding-left: 2px;
    padding-right: 4px;
    border-radius: 4px;
`;

export const Spacer = styled('div')`
    flex-grow: 1;
`;
