import { useEffect, useMemo, useState } from 'react';
import useWidgetConfig from '../../../hooks/useWidgetConfig';
import type { Props } from './types';
import useWebsocketOperationUpdater from '../../../hooks/useWebsocketOperationUpdater';
import { useSearchParams } from 'react-router-dom';
import useIframeParentCommunication from '../../../hooks/useIframeParentCommunication';
import { BasicWidgetProps } from '@hautechai/web-sdk/dist/basic/types';
import api from '../../../api';

const useLogic = (props: Props) => {
    const [searchParams] = useSearchParams();

    const parentCommunication = useIframeParentCommunication();
    const widgetConfig = useWidgetConfig();

    const [input, setInput] = useState<BasicWidgetProps['input']>(JSON.parse(atob(searchParams.get('input')!)));
    const stacksAPI = api.stack.useStacks(input.sessionId);

    const page = useMemo(() => {
        if (stacksAPI.listLoading) return 'loading';
        if (stacksAPI.list?.length === 0) return 'generate';
        return !!widgetConfig.state.stackId ? 'edit' : 'list';
    }, [stacksAPI.list, stacksAPI.listLoading, widgetConfig.state]);

    useWebsocketOperationUpdater(input.sessionId);
    useEffect(() => {
        if (!parentCommunication.message) return;

        switch (parentCommunication.message.topic) {
            case 'updateInput': {
                setInput(parentCommunication.message.data);
                widgetConfig.setState({});
                return;
            }
            default:
                return;
        }
    }, [parentCommunication.message, widgetConfig.setState]);

    return {
        input,
        page,
    };
};

export default useLogic;
