import { useCallback, useMemo, useState } from 'react';
import type { Props } from './types';
import api from '../../../../api';
import useWidgetConfig from '../../../../hooks/useWidgetConfig';

const useLogic = (props: Props) => {
    const operationsAPI = api.operation.useOperations();
    const stackAPI = api.stack.useStacks(props.input.sessionId);

    const [loading, setLoading] = useState(false);

    const onGenerate = useCallback(async () => {
        setLoading(true);

        try {
            const operationResult = await operationsAPI.generate({
                productImageUrl: props.input.image.url,
                prompt: props.input.prompt,
                seed: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
                version: '1',
            });
            if (!operationResult.data) return;

            await stackAPI.createMany({ generateOperationId: operationResult.data.id });
        } finally {
            setLoading(false);
        }
    }, []);

    return { loading, onGenerate };
};

export default useLogic;
