import { useCallback, useMemo, useState } from 'react';
import type { Props } from './types';
import useWidgetConfig from '../../../../hooks/useWidgetConfig';
import api from '../../../../api';
import utils from '../../../../utils';
import { OperationRunResponse } from '../../../../api/types';

const useLogic = (props: Props) => {
    const widgetConfig = useWidgetConfig();
    const stacksAPI = api.stack.useStacks(props.input.sessionId);

    const [operationStarting, setoperationStarting] = useState(false);

    const [stackImageSize, setStackImageSize] = useState<{ height: number; width: number }>();
    const stackImageSizeRatio = useMemo(
        () => (stackImageSize ? stackImageSize.width / stackImageSize.height : 1),
        [stackImageSize],
    );

    const stack = useMemo(
        () => stacksAPI.list?.find((stack) => stack.id === widgetConfig.state.stackId)!,
        [widgetConfig.state.stackId, stacksAPI.list],
    );
    const stackImage = useMemo(() => utils.stack.getStackImage(stack), [stack]);
    const operationsBlocked = useMemo(
        () => stackImage.loading || operationStarting || stacksAPI.listLoading,
        [operationStarting, stackImage, stacksAPI.listLoading],
    );

    const onClose = useCallback(() => {
        widgetConfig.setState({ ...widgetConfig.state, stackId: undefined });
    }, [widgetConfig.state]);
    const onStackImageLoad = useCallback((event: any) => {
        const image = event.target;
        setStackImageSize({ height: image.naturalHeight, width: image.naturalWidth });
    }, []);

    const runOperation = useCallback(
        (
            runner: (data: {
                imageUrl: string;
                previousOperationExternalId: string;
                previousOperationId: number;
            }) => Promise<OperationRunResponse>,
        ) => {
            return async () => {
                const stackImage = utils.stack.getStackImage(stack);
                if (!stackImage?.url) return;

                const lastOperation = stack.operations.filter((operation) => operation.operationId !== 'upscale')[0];

                setoperationStarting(true);
                try {
                    const response = await runner({
                        imageUrl: stackImage.url,
                        previousOperationExternalId: lastOperation.externalId,
                        previousOperationId: lastOperation.id,
                    });
                    if (!response.data) return;

                    await stacksAPI.update({ id: stack.id, data: { operationId: response.data?.id } });
                } finally {
                    setoperationStarting(false);
                }
            };
        },
        [stack.id, stacksAPI.update],
    );

    return {
        onClose,
        onStackImageLoad,
        operationsBlocked,
        runOperation,
        stack,
        stackImage,
        stackImageSizeRatio,
    };
};

export default useLogic;
