import { styled } from '@mui/material/styles';

const style = {
    Body: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    `,
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        padding: 8px;
        width: 100%;
    `,
    Header: styled('div')`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    `,
    Image: styled('img')<{ ratio: number }>`
        bottom: 0px;
        left: 0px;
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
    `,
    ImageContainer: styled('div')<{ ratio: number }>`
        align-items: center;
        border: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: calc((100vw - 32px) / ${({ ratio }) => ratio});
        width: calc(100vw - 32px);
    `,
};

export default style;
