import { styled } from '@mui/material';

export const Container = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
    height: 422px;
`;

export const Card = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 24px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
`;

export const BaseImage = styled('div')<{ url: string }>`
    display: flex;
    align-items: end;
    background-image: url(${({ url }) => url});
    background-size: cover;
`;

export const CardImage = styled(BaseImage)`
    flex: 1;
`;

export const ApparelImage = styled(BaseImage)`
    width: 103px;
    height: 103px;
`;

export const CardTitle = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.actions.onPrimary};
    height: 56px;
`;

export const PromtContainer = styled('div')`
    background-color: ${({ theme }) => theme.palette.actions.onPrimary};
    padding: 24px;
    flex: 1;
`;

export const Prompt = styled('div')`
    border-radius: 8px;
    border: 1px dashed ${({ theme }) => theme.palette.layout.strokes};
    background-color: ${({ theme }) => theme.palette.layout.surface.low};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.layout.onSurface.secondary};
    padding: 16px;
    trim: trailing;
`;

export const PromptHeader = styled('div')`
    color: ${({ theme }) => theme.palette.layout.onSurface.tertiary};
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
`;
