import apiAuth from '../../api/auth';
import apiMethods from '../../api/methods';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useWidgetConfig from '../../hooks/useWidgetConfig';

const WidgetSetup = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const load = async () => {
            const authToken = searchParams.get('token');
            const inputData = searchParams.get('input');
            const widgetType = searchParams.get('widget');

            apiAuth.token = authToken ?? undefined;

            const user = await apiMethods.user.getUser();
            apiAuth.userId = user.id;

            navigate(`/widget/${widgetType}?input=${inputData}&widget=${widgetType}`);
        };
        load();
    }, [searchParams]);

    return null;
};

export default WidgetSetup;
