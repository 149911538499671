import { CircularProgress, Typography } from '@mui/material';
import S from './style';
import { Props } from './types';

const Loader = (props: Props) => (
    <S.Container>
        <CircularProgress />
        {props.text && (
            <S.Text fontWeight={500} variant="body1">
                {props.text}
            </S.Text>
        )}
    </S.Container>
);

export default Loader;
