import { styled } from '@mui/material/styles';
import { TextField, Typography } from '@mui/material';

const DEFAULT_HEIGHT = 300;
const EMPTY_HEIGHT = DEFAULT_HEIGHT + 128;
const TEXT_TO_IMAGE_HEIGHT = DEFAULT_HEIGHT + 100;

export const Container = styled('div')<{ size?: 'default' | 'text-to-image' }>`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 16px;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.palette.layout.surface.mid};
    height: ${({ size }) => (size === 'text-to-image' ? TEXT_TO_IMAGE_HEIGHT : DEFAULT_HEIGHT)}px;
    overflow: scroll;
`;

export const DropzoneContainer = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 16px;
`;

export const FileDropzoneContainer = styled('div')`
    width: 100%;
    flex: 1;
`;

export const HorizontalContainer = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
`;

export const PromptInput = styled(TextField)`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.layout.surface.low};
    border: solid 0.5px ${({ theme }) => theme.palette.layout.strokes};
    border-radius: 4px;

    & fieldset {
        border: none;
    }

    & .MuiInputBase-root {
        height: 100%;
    }

    & textarea {
        height: 100% !important;
    }
`;

export const SeedInput = styled(TextField)`
    width: 256px;

    & .MuiOutlinedInput-root {
        background-color: ${({ theme }) => theme.palette.layout.surface.low};
    }

    & input {
        padding: 2px 8px;
    }
`;

export const VerticalContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

export const PlaceholderIconsContainer = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
`;

export const PlaceholderLink = styled(Typography)`
    display: inline;
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.actions.primary};
`;

export const PromptPreviewContainer = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.layout.onSurface.secondary};
`;

export const InitContainer = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 8px;
    padding: 12px 16px;
`;

export const InitContainerLeftSide = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;
`;

export const InitContainerRightSide = styled('div')`
    display: flex;
    width: 300px;
    flex-direction: column;
    gap: 8px;
`;

export const InitSectionTitle = styled('span')`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

export const RightDescriptionContainer = styled('div')`
    display: flex;
    border-radius: 8px;
    flex: 1;
    gap: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.layout.surface.mid};
    cursor: pointer;
`;

export const RightDescription = styled('div')`
    width: 192px;
    text-align: center;
`;
