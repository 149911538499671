import { useCallback, useMemo, useState } from 'react';
import api from '../../../../api';
import type { Props } from './types';
import utils from '../../../../utils';
import useWidgetConfig from '../../../../hooks/useWidgetConfig';

const useLogic = (props: Props) => {
    const widgetConfig = useWidgetConfig();

    const operationsAPI = api.operation.useOperations();
    const stacksAPI = api.stack.useStacks(props.input.sessionId);

    const stacks = useMemo(() => stacksAPI.list ?? [], [stacksAPI.list]);
    const images = useMemo(() => stacks.map(utils.stack.getStackImage), [stacks]);

    const hasLoadingImages = useMemo(() => images.some((image) => image.loading), [images]);
    const nonLoadingImages = useMemo(() => images.filter((image) => !image.loading), [images]);

    const [processing, setProcessing] = useState(false);

    const onClickGenerateMore = useCallback(async () => {
        const operations = stacks[0].operations;
        if (operations.length === 0) return;

        setProcessing(true);

        try {
            const generateOperation = operations[operations.length - 1];
            const operationResult = await operationsAPI.generate({
                ...generateOperation.inputData,
                seed: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
            });
            if (!operationResult.data) return;

            await stacksAPI.createMany({ generateOperationId: operationResult.data.id });
        } finally {
            setProcessing(false);
        }
    }, [stacks]);

    const onClickImage = useCallback(
        (stackId: number) => {
            widgetConfig.setState({ ...widgetConfig.state, stackId });
        },
        [widgetConfig.state],
    );

    return {
        hasLoadingImages,
        nonLoadingImages,
        onClickGenerateMore,
        onClickImage,
        processing,
    };
};

export default useLogic;
