import { Card, Modal as MuiModal, Popover as MuiPopover } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormContainer = styled(Card)`
    outline: none;
`;

export const Modal = styled(MuiModal)`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Popover = styled(MuiPopover)`
    & .MuiPopover-paper {
        box-shadow: none;
        border: 0.5px solid ${({ theme }) => theme.palette.layout.strokes};
        border-radius: 24px;
    }
`;
