import { MaterialSymbol } from 'react-material-symbols';
import ActionsListButton from './ActionsListButton';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { Button, Tooltip, Typography } from '@mui/material';

const Actions = (props: Props) => {
    const { apparelActions, faceActions, onClickDownload, onClickUpscale, upscaleDisabled } = useLogic(props);
    return (
        <S.Container>
            <S.Row>
                <ActionsListButton actions={apparelActions} disabled={props.operationsBlocked} name="Apparel" />
                <ActionsListButton actions={faceActions} disabled={props.operationsBlocked} name="Face" />
                <Tooltip title="Upscale to 4k">
                    <S.UpscaleButton disabled={props.operationsBlocked || upscaleDisabled} onClick={onClickUpscale}>
                        <MaterialSymbol icon="4k" size={24} />
                    </S.UpscaleButton>
                </Tooltip>
            </S.Row>
            <Button disabled={props.operationsBlocked} onClick={onClickDownload} variant="contained">
                <Typography variant="body2">Add to gallery</Typography>
            </Button>
        </S.Container>
    );
};

export default Actions;
