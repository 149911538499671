import { styled } from '@mui/material/styles';

const style = {
    Container: styled('div')`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;
        justify-content: center;
        width: 100%;
    `,
    Image: styled('img')`
        border: 1px solid lightgray;
        height: 128px;
        width: auto;
    `,
    ImageContainer: styled('div')`
        align-items: center;
        border: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
    `,
};

export default style;
