import * as Sentry from '@sentry/react';

const init = () => {
    const dsn = process.env.REACT_APP_SENTRY_DSN;
    if (!dsn) return;

    Sentry.init({
        dsn,
        environment: process.env.REACT_APP_ENV_NAME,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        tracesSampleRate: 1.0,

        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
    });
};

init();
