import { useCallback, useMemo, useState } from 'react';
import api from '../../../../../api';
import type { Props } from './types';
import utils from '../../../../../utils';
import useIframeParentCommunication from '../../../../../hooks/useIframeParentCommunication';

const useLogic = (props: Props) => {
    const parentCommunication = useIframeParentCommunication();

    const operationsAPI = api.operation.useOperations();

    const apparelActions = useMemo(
        () => [
            { name: 'Retouch bottom', onClick: props.runOperation((data) => operationsAPI.retouchBottom(data)) },
            { name: 'Retouch dress', onClick: props.runOperation((data) => operationsAPI.retouchDress(data)) },
            { name: 'Retouch top', onClick: props.runOperation((data) => operationsAPI.retouchTop(data)) },
        ],
        [props.runOperation],
    );
    const faceActions = useMemo(
        () => [
            { name: 'Retouch face', onClick: props.runOperation((data) => operationsAPI.retouchFace(data)) },
            {
                name: 'Replace face',
                onClick: props.runOperation((data) => operationsAPI.replaceFace(data)),
            },
        ],
        [props.runOperation],
    );

    const imageUrl = useMemo(() => utils.stack.getStackImage(props.stack).url, [props.stack.operations]);
    const onClickDownload = useCallback(() => {
        parentCommunication.sendMessage('downloadImage', { url: imageUrl });
    }, [imageUrl]);

    const upscaleDisabled = useMemo(
        () => props.stack.operations.some((operation) => operation.operationId === 'upscale4k'),
        [props.stack],
    );
    const onClickUpscale = useCallback(
        props.runOperation((data) => operationsAPI.upscale4k({ ...data, version: 1 })),
        [props.runOperation],
    );

    return {
        apparelActions,
        faceActions,
        onClickDownload,
        onClickUpscale,
        upscaleDisabled,
    };
};

export default useLogic;
