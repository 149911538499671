import { styled } from '@mui/material/styles';

export const Chip = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.layout.onSurface.secondary};
`;

export const Icon = styled('div')<{ iconUrl?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 0.5px solid ${({ theme }) => theme.palette.layout.strokes};
    background-image: url(${({ iconUrl }) => iconUrl});
    background-size: cover;
`;

export const Empty = styled('div')`
    width: 14px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${({
        theme,
    }) =>
        encodeURIComponent(
            theme.palette.layout.onSurface.secondary,
        )}' stroke-width='1' stroke-dasharray='1 3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
`;
