import { styled } from '@mui/material/styles';
export const Avatar = styled('img')`
    border-radius: 50%;
    height: 32px;
    width: 32px;
`;

export const Button = styled('div')<{ selected: boolean }>`
    color: black;
    font-size: 16px;
    font-weight: ${({ selected }) => (selected ? 'bold' : 400)};

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

export const Buttons = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-left: 32px;
`;

export const Container = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 24px;
    height: 120px;
`;

export const Logo = styled('img')`
    cursor: pointer;
`;

export const LogoContainer = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

export const LogoText = styled('div')`
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: default;
    font-size: 20px;
    font-weight: 600;
    margin-left: 8px;
`;

export const RightContainer = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
`;

export const GenerateContainer = styled('div')`
    display: flex;
    flex-grow: 1;
    justify-content: center;
`;
